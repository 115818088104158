import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'sortBy',
})
export class SortByPipe implements PipeTransform {

    transform(values: readonly any[], key = 'name', reload: number = null, isNumber = false, ...args: unknown[]): readonly any[] {
        if (!values) {
            return values;
        }

        const newValues = [...values]
        if (isNumber) {
            return newValues.sort((a, b) => a[key] > b[key] ? 1 : -1);
        } else {
            return newValues.sort((a, b) => a[key].toLowerCase() > b[key].toLowerCase() ? 1 : -1);
        }
    }

}
