import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Pipe({
    name: 'appNumber'
})
export class AppNumberPipe implements PipeTransform {

    transform(value: number, ...args: unknown[]): string {
        return new DecimalPipe('en').transform(value, '0.0-2')?.replace(',', '')
    }

}
