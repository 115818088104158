import {Pipe, PipeTransform} from '@angular/core';
import { ServiziCliente } from '../models/servizio-cliente.model';

@Pipe({
    name: 'filterServices'
})
export class FilterServicesPipe implements PipeTransform {

    transform(values: ServiziCliente[], searchText: string, ...args: unknown[]): ServiziCliente[] {
        if (!values)
            return [];
        if (searchText) {
            searchText = searchText.toLowerCase();
            values = values.filter(s => s.Nome.toLowerCase().includes(searchText));
        }
        return values;
    }

}
