import {Pipe, PipeTransform} from '@angular/core';
import {ClientiByStatus} from '../models/cliente.model';

@Pipe({
    name: 'searchClienti'
})
export class SearchClientiPipe implements PipeTransform {

    transform(values: any[], search: string, ...args: unknown[]): ClientiByStatus[] {
        return values ? values
            .filter(e =>
                (search == '') ||
                (search != '' &&
                    (e.Cliente.Nome.toLowerCase().includes(search.toLowerCase())
                        || (e.Cliente.UserId.toLowerCase().includes(search.toLowerCase()))
                        || (e.Cliente.CodiceCliente?.toLowerCase().includes(search.toLowerCase()))
                        || ((e.Cliente.Id + '').includes(search))
                    )
                )
            ).sort(
                (a, b) => (!a['blocked'] && b['blocked']) ? 1 : -1
            ) : null;
    }

}
