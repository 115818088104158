import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Pipe({
    name: 'sanitizerUrl'
})
export class SanitizerPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }

    transform(value: string, isHtml = false, ...args: unknown[]): SafeResourceUrl {
        if (isHtml) {
            return (value) ? this.sanitizer.bypassSecurityTrustHtml(value) : value;
        }
        return (value) ? this.sanitizer.bypassSecurityTrustResourceUrl(value) : value;
    }

}
