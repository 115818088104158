import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'app-add-video-modal',
	templateUrl: './add-video-modal.component.html',
	styleUrls: ['./add-video-modal.component.scss'],
})
export class AddVideoModalComponent implements OnInit {
	videoUrl: string;
	videoFile: File;

	constructor(private modalCtrl: ModalController) {}

	ngOnInit(): void {}

	onFileChange(event: Event) {
		const input = event.target as HTMLInputElement;
		if (input.files && input.files.length > 0) {
			this.videoFile = input.files[0];
		}
	}

	close() {
		this.modalCtrl.dismiss().then();
	}

	save() {
		this.modalCtrl
			.dismiss({
				videoUrl: this.videoUrl,
				videoFile: this.videoFile,
			})
			.then();
	}
}
