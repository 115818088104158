import {Pipe, PipeTransform} from '@angular/core';
import {ProdottoMagazzino, ProductType} from '../models/prodottoMagazzino.model';

@Pipe({
    name: 'filterProducts'
})
export class FilterProductsPipe implements PipeTransform {

    transform(values: ProdottoMagazzino[], searchText = '', categoryIds: number[],
              productType?: ProductType,
              onlySelected = false, ...args: unknown[]): ProdottoMagazzino[] {
        if (!values) {
            return [];
        }

        if (productType)
            values = values.filter(p => p.ProductType === productType);

        let array = values;
        if (onlySelected) {
            return array.filter(e => e.newQuantita !== 0).map((e) => {
                e['IsDownloaded'] = false;
                return e;
            });
        } else {
            if (searchText) {
                searchText = searchText.toLowerCase();
                array = array.filter(e => e.Nome.toLowerCase().includes(searchText) || (e.BarCodes?.some(b => b.BarCode.includes(searchText))));
            }
            if (categoryIds?.length) {
                array = array.filter(e => categoryIds.includes(e.IdCategoria));
            }
            // if (idBrand && idBrand !== '0') {
            //     array = array.filter(e => e.BrandProdotto && e.BrandProdotto.Id + '' === idBrand);
            // }
            return array.map((e) => {
                e['IsDownloaded'] = false;
                return e;
            });
        }

    }

}
