// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	appVersion: require('../../package.json').version + '-test',
	production: true,
	smsGateway: '6Aq7',
	smsUser: 'digitalev',
	smsPassword: '6Aq7Ob/t47M',
	separatorChips: '#;',
	qualityImage: 10,
	serviceMaxCharacter: 40,
	serviceDescriptionMaxCharacter: 400,
	wsApiUrl: 'https://salonmenu-test.osb.solutions/wsSA',
	wsApiUrlSalonMenu: 'https://salonmenu-test.osb.solutions/wsSM',
	hasMobileApp: true,
	appleId: '1607281029',
	hostName: 'https://apisalonapp.osb.solutions/',
	pageItemForsList: 10,
	googleAnalyticsKey: '',
	paginationLength: 50,
	appName: 'Salon App Business',
	bundleName: 'salonapplus',
	bundleDomain: '.digitalevolutionitalia.it',
	firebaseConfig: {
		apiKey: 'AIzaSyAs2MipG23C2z3NRhpjP9btAmvEwBEANqk',
		authDomain: 'agenda-2dcd7.firebaseapp.com',
		projectId: 'agenda-2dcd7',
		storageBucket: 'agenda-2dcd7.appspot.com',
		messagingSenderId: '1086341044019',
		appId: '1:1086341044019:web:637529a628583f4c185046',
		measurementId: 'G-01RST4YKVM'
	},
	bottomTabs: [
		'calendario',
		'rubrica',
		'fiches',
		'reports',
		'gallery',
		'menu',
		'archive',
		'seminars',
		'services',
		'template-settings',
		'salon-profile',
		'davines'
	],
	topTabs: [
		'template-settings',
		'menu-collaborators',
		'menu-price-list',
		'menu-warehouse',
		'menu-promotions',
		'menu-checkapp',
		'menu-users',
		'menu-tenants',
		'menu-support',
		'menu-profile',
		'menu-report-fiche'
	],
	navigationCards: [
		{
			name: 'calendario',
			icon: 'calendar-outline'
			//image: 'assets/images/home_cards/01.jpg'
		},
		{
			name: 'rubrica',
			icon: 'people-outline'
			//image: 'assets/images/home_cards/02.jpg'
		},
		{
			name: 'fiches',
			icon: 'list-outline'
		},
		{
			name: 'reports',
			icon: 'stats-chart-outline'
		},
		{
			name: 'gallery',
			icon: 'images-outline'
		},
		{
			name: 'menu',
			icon: 'document-outline'
		},
		{
			name: 'archive',
			icon: 'file-tray-stacked-outline'
		},
		{
			name: 'seminars',
			icon: 'book-outline'
		},
		{
			name: 'services',
			icon: 'list-outline'
		},
		{
			name: 'template-settings',
			icon: 'settings-outline'
		},
		{
			name: 'salon-profile',
			icon: 'person-circle-outline'
		}
	]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
