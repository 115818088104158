import {Pipe, PipeTransform} from '@angular/core';
import {MenuServiceVModel} from '../models/menu-page-edit.model';

@Pipe({
    name: 'filterMenuServices'
})
export class FilterMenuServicesPipe implements PipeTransform {

    transform(values: MenuServiceVModel[], searchText: string, ...args: unknown[]): MenuServiceVModel[] {
        if (!values)
            return [];
        if (searchText) {
            searchText = searchText.toLowerCase();
            values = values.filter(s => s.name.toLowerCase().includes(searchText));
        }
        return values;
    }

}
