import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'checkStorageLink'
})
export class CheckStorageLinkPipe implements PipeTransform {

    constructor() {

    }

    transform(srcImg: string, storageAccount: string, ...args: unknown[]): string {
        const basePath = (srcImg.includes('base64,') ||
        srcImg.includes(storageAccount)
            ? '' : storageAccount);
        return basePath + srcImg;
    }

}
