import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InputLabelComponent} from './input-label.component';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {PipesModule} from '../../pipes/pipes.module';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';


@NgModule({
    declarations: [InputLabelComponent],
    exports: [InputLabelComponent],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        PipesModule,
        NgxMaterialTimepickerModule
    ]
})
export class InputLabelModule {
}
