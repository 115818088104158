import { Pipe, PipeTransform } from '@angular/core';
import { ScheduleEventAppointment } from '../models/appuntamenti.model';

@Pipe({
	name: 'counterCustomer',
})
export class CounterCustomerPipe implements PipeTransform {
	transform(values: ScheduleEventAppointment[], ...args: unknown[]): number {
		return Array.from(new Set(values.filter((e) => e.hasNumber && !e.Ricordato).map((e) => e.IdCustomer))).length;
	}
}
