import { Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IonDatetime, IonModal } from '@ionic/angular';

@Component({
	selector: 'app-input-label',
	templateUrl: './input-label.component.html',
	styleUrls: ['./input-label.component.scss'],
})
export class InputLabelComponent implements OnInit, OnChanges {
	@Input() isEdit = true;
	@Input() type: 'textarea' | 'date' | 'text' | 'tel' | 'number' | 'password' | 'checkbox' | 'email' | 'hour' = 'text';
	@Input() label: string;
	@Input() model: string | Date | boolean;
	@Input() placeholder = '';
	@Input() isNewPassword = false;
	@Input() rows = 2;
	@Input() autocomplete = 'off';
	@Input() classItem = '';
	@Input() iconStart: string;
	@Input() readOnly = false;
	@Input() required = false;
	@Input() presentation: 'date' | 'time' = 'date';
	@Input() classInput: 'no-padding-horizontal' | '' = '';
	@Input() min = null;
	@Input() max: Date | number = this.type === 'date' ? new Date() : null;
	@Input() maxHour: string = null;
	@Input() minuteValues = [0, 15, 30, 45];
	@Input() minutesGap = 10;
	@Input() showCalendar = true;
	@Output() modelChange = new EventEmitter<string | Date | boolean>();

    @HostBinding('attr.invalid') get invalid(){
        if(this.required && !this.model)
            return '';
        return null;
    }
	passwordIcon = 'eye-off';
	passwordType = 'password';
	idRandom = Math.random() * 1000;

	maxString = null;
	minString = null;

	constructor() {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.max && this.max instanceof Date) {
			this.maxString = this.max?.toISOString();
		}

		if (changes.min && this.min instanceof Date) {
			this.minString = this.min?.toISOString();
		}
	}

	ngOnInit() {
		if (this.type === 'date' && this.model instanceof Date) this.model = this.model.toISOString();
	}

	hideShowPassword() {
		this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
		this.passwordIcon = this.passwordIcon === 'eye-off' ? 'eye' : 'eye-off';
	}

	isValidPassword(password: string) {
		var re = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
		return re.test(password);
	}

	containsUpperChar(password: string) {
		var re = /\w*[A-Z]\w*/;
		return re.test(password);
	}

	containsNumber(password: string) {
		var re = /\w*[0-9]\w*/;
		return re.test(password);
	}

	containsSpecial(password: string) {
		var re = /\w*[@$!%*?&]\w*/;
		return re.test(password);
	}

	onCloseDateModal(modal: IonModal) {
		modal.dismiss();
	}

	onAccpetDateModal(ionDatetime: IonDatetime, modal: IonModal) {
		this.modelChange.emit(ionDatetime.value + '');
		modal.dismiss();
	}

	changeDate(ionDatetime: IonDatetime, modal: IonModal) {
		if (ionDatetime.value !== this.model) {
			this.modelChange.emit(ionDatetime.value + '');
			modal.dismiss();
		}
	}

    event(e: any){
        console.log(e);
    }
}
