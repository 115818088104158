import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AlertController, IonSlides, ModalController, PopoverController } from '@ionic/angular';
import { BehaviorSubject, combineLatest, firstValueFrom, lastValueFrom, Observable, of } from 'rxjs';
import { GalleryService } from '../../../services/gallery.service';
import { map, shareReplay, tap } from 'rxjs/operators';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'src/app/services/users.service';
import { CompressComponent } from '../photo-detail/compress/compress.component';
import { TemplateService } from 'src/app/services/template.service';
import { VisibilityPhotoComponent } from '../photo-detail/visibility-photo/visibility-photo.component';
import { PhotoI } from '../PhotoI';
import { PermissionsUsersComponent } from '../photo-detail/permissions-users/permissions-users.component';
import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { LoadingService } from '../../../services/loading.service';
import { MenusService } from '../../../services/menus.service';
import { TagService } from '../../../services/tag.service';
import { GalleryFileVModel } from '../../../models/gallery-file-v.model';
import { CategoriaCliente } from '../../../models/categoria-cliente.model';
import { GalleryFileModel } from '../../../models/gallery-file.model';
import { IAlbum } from '../../../models/IAlbum';
import { TagCategory } from '../../../models/tag-category';
import { SelectGroupModalComponent } from '../../groups/select-group-modal/select-group-modal.component';
import { Group, GroupFeature } from '../../../interfaces/group.interface';
import { Router } from '@angular/router';
import { GroupsService } from '../../../services/groups.service';

export interface GalleryEditorModalResult {
	deleteFile?: GalleryFileVModel;
	addedFile?: GalleryFileVModel;
}

@Component({
	selector: 'app-gallery-file-editor',
	templateUrl: './gallery-file-editor.component.html',
	styleUrls: ['./gallery-file-editor.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [SocialSharing],
})
export class GalleryFileEditorComponent implements OnInit {
	@Input() galleryFile: GalleryFileVModel;
	@Input() galleryFiles: GalleryFileVModel[];
	@Input() newGalleryFiles: GalleryFileVModel[];
	@Input() isModal = false;
	@Input() isNew = false;
	@Input() isEdit = false;
	@Input() isMultiple = false;
	@Input() idTemplate: number;
	@Output() share = new EventEmitter();
	@ViewChild(IonSlides, { static: false }) slides: IonSlides;

	isSede = false;
	galleryFiles$: Observable<GalleryFileVModel[]>;
	currentFile$: Observable<GalleryFileVModel>;
	currentFileIndex$ = new BehaviorSubject(0);
	albums$: Observable<IAlbum[]>;
	allMacroTags$: Observable<TagCategory[]>;
	currentIndex: number;
	slideOpts = {
		initialSlide: 0,
		speed: 100,
		lazy: true,
	};
	isMobile = false;
	compressQuality = 20;
	isMultiType = false;
	showPhotoPreview = false;
	showVideoPreview = false;
	categories$: Observable<CategoriaCliente[]>;

	private groupsAssignedTo: Group[] = [];

	constructor(
		private router: Router,
		private groupsService: GroupsService,
		private galleryService: GalleryService,
		private loadingService: LoadingService,
		private tagService: TagService,
		private modalCtrl: ModalController,
		private userService: UsersService,
		private toastService: ToastService,
		private translateService: TranslateService,
		public templateService: TemplateService,
		public alertController: AlertController,
		private popoverController: PopoverController,
		private menusService: MenusService,
	) {
		this.isMobile = window.innerWidth <= 767;
	}

	ngOnInit() {
		this.loadingService.show();

		if (!this.idTemplate) {
			firstValueFrom(this.menusService.getCurrentMenu()).then((menu) => {
				this.idTemplate = menu.Id;
				this.ngOnInit();
			});
		}

		this.categories$ = of([]);

		this.isSede = this.menusService.currentMenu.IsSharingAllowed;
		if (!this.isNew) {
			this.galleryFiles$ = of(this.galleryFiles).pipe(
				tap((files) => {
					const index = files.findIndex((f) => f.file.Id === this.galleryFile.file.Id);
					this.slideOpts.initialSlide = index;
					this.currentFileIndex$.next(index);
				}),
				shareReplay(1),
			);
		} else {
			this.isEdit = true;
		}

		this.showPhotoPreview = this.galleryFile.file.FileType === 0;
		this.showVideoPreview = this.galleryFile.file.FileType === 1;

		this.albums$ = this.galleryService
			.getAlbum()
			.pipe(
				map((albums) =>
					albums.sort((a, b) => (a.Anno + a.Nome.toLowerCase() > b.Anno + b.Nome.toLowerCase() ? 1 : -1)),
				),
			)
			.pipe(shareReplay(1));

		const isArchive = this.galleryFile.file.FileType === 2;

		this.allMacroTags$ = this.tagService.getMacroTags(isArchive).pipe(shareReplay(1));

		combineLatest([this.categories$]).subscribe(([]) => {
			this.loadingService.hide();
		});

		if (!this.isNew) {
			this.currentFile$ = combineLatest([this.galleryFiles$, this.currentFileIndex$]).pipe(
				map(([files, currentIndex]) => {
					this.currentIndex = currentIndex;
					this.galleryFile = files[currentIndex];

					this.loadGroupsAssignedTo();

					return this.galleryFile;
				}),
			);

			this.currentFile$.subscribe(() => {
				this.loadingService.hide();
			});
		}
	}

	onIonSlideDidChange(event: Event) {
		this.slides.getActiveIndex().then((index) => {
			this.currentFileIndex$.next(index);
		});
	}

	onBackNavigate() {
		if (this.galleryFile) {
			this.galleryFile.newBase64 = null;
		}
		this.modalCtrl.dismiss();
	}

	openVisibility(event: any) {
		this.slides.getActiveIndex().then(async (index) => {
			const photo: PhotoI = this.galleryFile.toPhotoModel();
			this.popoverController
				.create({
					component: VisibilityPhotoComponent,
					event,
					translucent: true,
					componentProps: { photo },
				})
				.then((menu) => {
					menu.present();
					menu.onDidDismiss<PhotoI>().then(async (resp) => {
						if (resp.data) {
							this.galleryFile.file.State = resp.data.MetaData.Stato;
							await this.updateFile(this.galleryFile);
						}
					});
				});
		});
	}

	openUsers() {
		this.slides.getActiveIndex().then(async (index) => {
			const photo: PhotoI = this.galleryFile.toPhotoModel();
			this.modalCtrl
				.create({
					component: PermissionsUsersComponent,
					componentProps: { photo },
					cssClass: ['full-modal'],
				})
				.then((modal) => {
					modal.present();
					modal.onDidDismiss().then((resp) => {});
				});
		});
	}

	lockSlides() {
		this.slides.lockSwipes(this.isEdit);
	}

	async onSaveClick(file: GalleryFileVModel) {
		console.log('onSaveClick', file);

		if (this.isNew) {
			let continueContinue = false;
			const alert = await this.alertController.create({
				header:
					'Dichiaro che l’immagine è stata lecitamente acquisita e di avere tutti i diritti per il caricamento della stessa.',
				inputs: [
					{
						label: 'Confermo',
						type: 'checkbox',
						value: 'red',

						handler: (value) => {
							console.log(value);
							if (value.checked) {
								alert.buttons = [
									{
										text: 'Annulla',
										role: 'cancel',
									},
									{
										text: 'Salva',
										role: 'confirm',
										handler: () => {
											continueContinue = true;
										},
									},
								];
							} else {
								alert.buttons = [
									{
										text: 'Annulla',
										role: 'cancel',
									},
								];
							}
						},
					},
				],
				buttons: [
					{
						text: 'Annulla',
						role: 'cancel',
					},
				],
			});

			await alert.present();
			await alert.onDidDismiss();

			if (!continueContinue) {
				return;
			}

			this.loadingService.show();
			if (this.isMultiple) {
				this.createGalleryFiles(file);
			} else {
				await this.createGalleryFile(file);
			}
			this.loadingService.hide();
		} else {
			this.loadingService.show();
			await this.updateFile(file);
			this.loadingService.hide();
		}

		this.isEdit = false;
	}

	createGalleryFiles(singleFile: GalleryFileVModel) {
		const galleryFiles: GalleryFileVModel[] = [];

		[...this.newGalleryFiles].forEach((galleryFile) => {
			let file: GalleryFileModel = {
				...galleryFile.file,
				TemplateId: singleFile.file.TemplateId,
				AlbumId: singleFile.file.AlbumId,
				Description: singleFile.file.Description,
				TagCategories: singleFile.file.TagCategories,
				Name: singleFile.file.Name,
				NameGallery: singleFile.file.NameGallery,
				State: singleFile.file.State,
			};

			galleryFiles.push(new GalleryFileVModel(file));
		});

		this.modalCtrl.dismiss(galleryFiles).then();
	}

	async createGalleryFile(file: GalleryFileVModel) {
		return new Promise<void>((resolve) => {
			file.isProcessing = true;
			if (file.newBase64) {
				file.file.FileBase64 = file.newBase64;
			}

			this.galleryService.createGalleryFile(file.file).subscribe(async (res) => {
				console.log('createGalleryFile res', res);

				file.file.Id = res.Id;
				file.file.Url = res.Url;
				file.file.ThumbnailUrl = res.ThumbnailUrl;
				file.file.UrlWebp = res.UrlWebp;
				file.file.UrlVideo = res.UrlVideo;
				file.file.IsFinished = res.IsFinished;
				file.isProcessing = false;
				file.newBase64 = null;
				file.file.State = res.State;

				this.toastService.presentToast(this.translateService.instant('saveSuccess'));
				const result: GalleryEditorModalResult = {
					addedFile: file,
				};

				console.log('createGalleryFile result', result);

				await this.modalCtrl.dismiss(result);
				resolve();
			});
		});
	}

	async updateFile(file: GalleryFileVModel) {
		file.isProcessing = true;
		file.file.FileBase64 = file.newBase64;
		const res = await lastValueFrom(this.galleryService.updateGalleryFile(file.file));
		file.file.Url = res.Url;
		file.file.ThumbnailUrl = res.ThumbnailUrl;
		file.file.UrlWebp = res.UrlWebp;
		file.file.UrlVideo = res.UrlVideo;
		file.file.IsFinished = res.IsFinished;
		file.isProcessing = false;
		file.newBase64 = null;
		file.file.State = res.State;
		this.toastService.presentToast(this.translateService.instant('saveSuccess'));
		this.modalCtrl.dismiss();
	}

	async sendPhoto(photo: GalleryFileModel = null) {
		// if (this.photos.length === 0) {
		//   const macroTags = this.photo.MetaData.macroTags;
		//   this.photo.MetaData.IdAlbum = this.photo.AlbumModel.Id;
		//   if (this.album) {
		//     if (this.usersService.currentUserInfo.isSede) {
		//       this.photo.MetaData.Stato = PhotoStateEnum.archiveSede;
		//     } else {
		//       this.photo.MetaData.Stato = PhotoStateEnum.archiveAll;
		//     }
		//   } else {
		//     if (this.usersService.currentUseInfo.isSede) {
		//       this.photo.MetaData.Stato = PhotoStateEnum.gallerySede;
		//     } else {
		//       this.photo.MetaData.Stato = PhotoStateEnum.galleryAll;
		//     }
		//   }
		//   if (this.usersService.currentUseInfo.isSede) {
		//     this.photo.IdTemplate = this.templateService.templateChoose$.value.IdSceltaTemplate;
		//   }
		//   this.photo.MetaData.isFromSalone = this.usersService.currentUseInfo.isSede ? '0' : '1';
		//   // @ts-ignore
		//   if (this.photo.MetaData.urlVideo) {
		//     // @ts-ignore
		//     this.photo.changeImage = {base64: this.photo.Link};
		//   }
		//   // @ts-ignore
		//   await this.templateService.savePhoto(this.photo.changeImage.base64, {...this.photo.MetaData, macroTags: JSON.stringify(macroTags)},
		//     true,  this.compressQuality, this.isMultiType)
		//     .toPromise().then(resp => {
		//       this.photo.Link = resp.Url;
		//       const urls: string[] = resp.Url.split('/');
		//       this.photo.Name = urls.slice(urls.length - 2, urls.length).join('/');
		//       this.toastService.presentToast(this.translate.instant('saveSuccess'));
		//       this.photo.MetaData.webp = this.photo.Link.replace('.jpeg', '.webp').replace('.png', '.webp');
		//       this.photo.MetaData.webp = this.photo.MetaData.webp.replace('/' + this.photo.IdTemplate, '/' + this.photo.IdTemplate + '/webp');
		//       this.photo.MetaData.thumbnail = this.photo.Link.replace('/' + this.photo.IdTemplate, '/' + this.photo.IdTemplate + '/thumbnail');
		//       this.close(this.photo);
		//     });
		//   return;
		// }
		// this.slides.getActiveIndex().then(async (index) => {
		//   if (photo != null) {
		//     this.photos[index] = photo;
		//   }
		//   this.photos[index].MetaData.IdAlbum = this.photos[index].AlbumModel ? this.photos[index].AlbumModel.Id : null;
		//   const obs$ = [this.httpService.saveMetadata(this.photos[index])];
		//   if (this.photos[index].changeImage) {
		//     obs$.push(this.httpService.patchImage(this.photos[index]));
		//   }
		//   forkJoin(obs$).subscribe(() => {
		//     if (obs$.length > 1) {
		//       this.photos[index].Link = this.photos[index].changeImage.base64;
		//       if (this.photos[index].MetaData.webp) {
		//         this.photos[index].MetaData.webp = this.photos[index].changeImage.base64;
		//       }
		//     }
		//     this.isEdit = false;
		//     this.toastService.presentToast(this.translate.instant('saveSuccess'));
		//     this.lockSlides();
		//   });
		// });
	}

	deletePhoto() {
		const result: GalleryEditorModalResult = {
			deleteFile: this.galleryFile,
		};
		this.modalCtrl.dismiss(result);

		// this.slides.getActiveIndex().then(async (index) => {
		//   const item = this.photos[index];
		//   const name = item.MetaData && item.MetaData.name ? item.MetaData.name : '';
		//   this.alertCtrl.create({

		//     header: 'Alert',
		//     message: 'Are you sure you want to delete the photo ' + name + '?',
		//     buttons: [
		//       {
		//         text: this.translate.instant('dismiss'),
		//         role: 'cancel',
		//         handler: data => {
		//           event.preventDefault();
		//           event.stopImmediatePropagation();
		//         }
		//       },
		//       {
		//         text: 'Si',
		//         handler: data => {

		//           const idTemplate = (this.usersService.currentUseInfo.isSede) ?
		//             this.templateService.templateChoose$.value.IdSceltaTemplate
		//             : this.templateService.templateChoose$.value.Id;
		//           this.httpService.deleteImage(item, idTemplate).subscribe(resp => {
		//             const i = this.photos.findIndex(e => e.Name === item.Name);
		//             this.photos.splice(i, 1);
		//             this.photos = JSON.parse(JSON.stringify(this.photos));
		//             this.slides.update();
		//             this.isEdit = false;
		//             if (this.photos.length === 0) {
		//               this.modalCtrl.dismiss();
		//             }
		//           });
		//         }
		//       }]
		//   }).then(alert => alert.present());

		// });
	}

	showCompress() {
		this.modalCtrl
			.create({
				component: CompressComponent,
				componentProps: { compressQuality: this.compressQuality },
			})
			.then((modal) => {
				modal.present();
				modal.onDidDismiss().then((result) => {
					if (result && result.data != null) {
						this.compressQuality = result.data;
					}
				});
			});
	}

	assignToGroup() {
		const isArchive = this.router.url.includes('archive');

		this.modalCtrl
			.create({
				component: SelectGroupModalComponent,
				componentProps: {
					forAssigning: true,
					feature: isArchive ? GroupFeature.archive : GroupFeature.gallery,
					selectedGroups: this.groupsAssignedTo,
				},
			})
			.then((modal) => {
				modal.present().then();
				modal.onDidDismiss().then((result) => {
					if (!result.data) return;

					this.galleryService
						.editFileGroups(
							this.galleryFile.file.Id,
							(result.data as Group[]).map((group) => group.Id),
							isArchive,
						)
						.subscribe(() => {
							this.toastService.presentToast('Gruppi del file aggiornati');
						});
				});
			});
	}

	showAssignToGroupsButton(): boolean {
		const isArchive = this.router.url.includes('archive');

		if (this.groupsService.myGroups$.value.length === 0) {
			this.groupsService.getMyGroups();
		}

		return (
			this.groupsService.myGroups$.value.filter(
				(group) => group.Feature === (isArchive ? GroupFeature.archive : GroupFeature.gallery),
			).length > 0 || this.groupsAssignedTo.length > 0
		);
	}

	private loadGroupsAssignedTo() {
		const isArchive = this.router.url.includes('archive');

		this.galleryService.getGroupsAssignedTo(this.galleryFile.file.Id, isArchive).subscribe((groups) => {
			this.groupsAssignedTo = groups.filter((group) => group.ResourceAssigned === true);
		});
	}

	private onShare() {
		const user = this.userService.currentUserInfo;

		// let url = user.MenuWebsites && user.MenuWebsites.length > 0 ? user.MenuWebsites[0].WebsiteAddress : null;
		// if (url && !url.includes('http')) {
		//   url = 'https://' + url;
		// } else if (!url) {
		//   if (environment.isBeautyMenu || window.location.href.includes('beautymenu')) {
		//     url = 'https://menu.beautymenu.io/' + this.usersService.currentUserInfo.id;
		//   } else {
		//     url = 'https://fleep.salonmenu.it/' + this.usersService.currentUserInfo.id;
		//   }
		// }
		let url = 'https://' + this.userService.website;
		// if (this.currentPage) {
		// //url += '/' + this.divs[this.indexSelected].namePage;
		// url += '/' + this.currentPage.namePage;
		// }
		if (Capacitor.getPlatform() !== 'web') {
			Share.share({
				title: 'SalonMenu',
				url: encodeURI(url),
			})
				.then(() => {})
				.catch(console.error);
		} else {
			navigator
				.share({
					title: 'SalonMenu',
					url: encodeURI(url),
				})
				.then(() => {})
				.catch(console.error);
		}
	}
}
