import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AlertController, IonSlides, ModalController, PopoverController } from '@ionic/angular';
import { ToastService } from '../../../services/toast.service';
import { PhotoI } from '../PhotoI';
import { TemplateService } from '../../../services/template.service';
import { TagsComponent } from '../tags/tags.component';
import { PhotoStateEnum } from '../PhotoStateEnum';
import { UsersService } from '../../../services/users.service';
import { VisibilityPhotoComponent } from './visibility-photo/visibility-photo.component';
import { forkJoin, Observable, of } from 'rxjs';
import { PermissionsUsersComponent } from './permissions-users/permissions-users.component';
import { CompressComponent } from './compress/compress.component';
import { TranslateService } from '@ngx-translate/core';
import { MenusService } from '../../../services/menus.service';
import { TagService } from '../../../services/tag.service';
import { GalleryService } from '../../../services/gallery.service';
import { IAlbum } from '../../../models/IAlbum';
import { TagCategory } from '../../../models/tag-category';
import { SelectGroupModalComponent } from '../../groups/select-group-modal/select-group-modal.component';
import { GroupFeature } from '../../../interfaces/group.interface';

@Component({
	selector: 'app-photo-detail',
	templateUrl: './photo-detail.component.html',
	styleUrls: ['./photo-detail.component.scss'],
})
export class PhotoDetailComponent implements OnInit {
	@ViewChild(IonSlides, { static: false }) slides: IonSlides;
	@Input() newPhotoSrc = '';
	@Input() urlVideo = '';
	@Input() album: IAlbum = null;
	@Input() photos: PhotoI[] = [];
	@Input() indexSelected: number;
	@Input() idTemplate: number;
	compressQuality = 20;
	photo: PhotoI = {
		Name: null,
		IdTemplate: null,
		GalleryFileId: 0,
		MetaData: { name: '', desc: '', macroTags: [], favorite: '0' },
		Link: '',
		AlbumModel: { Id: null, Anno: '', Nome: '' },
	};
	slideOpts = {
		initialSlide: 1,
		speed: 400,
		lazy: true,
	};
	isEdit = false;
	isMobile = false;
	showSlide = false;
	allMacroTags$: Observable<TagCategory[]>;
	slideEnd = 2;
	isMultiType = false;

	constructor(
		private alertCtrl: AlertController,
		private galleryService: GalleryService,
		private popoverController: PopoverController,
		public templateService: TemplateService,
		private modalCtrl: ModalController,
		private tagService: TagService,
		private translate: TranslateService,
		public usersService: UsersService,
		private toastService: ToastService,
		private menusService: MenusService,
	) {
		this.isMobile = window.innerWidth <= 767;
	}

	get currentUser() {
		return this.usersService.currentUserInfo;
	}

	ngOnInit(): void {
		if (!this.idTemplate) {
			throw Error('idTemplate is Required');
		}

		this.allMacroTags$ = this.tagService.getMacroTags();
		if (this.newPhotoSrc) {
			this.photos = [];
			this.photo.Link = this.newPhotoSrc;
			this.photo.MetaData.webp = this.newPhotoSrc;
			if (this.urlVideo) {
				this.photo.MetaData.urlVideo = this.urlVideo;
			}
			this.photo.AlbumModel = this.album || { Id: null, Anno: '', Nome: '' };
			this.isEdit = true;
			const dimension = (this.newPhotoSrc.length * (3 / 4) - 2) / 1000;
			if (dimension < 150) {
				this.compressQuality = 0;
			} else if (dimension >= 150 && dimension < 250) {
				this.compressQuality = 5;
			} else if (dimension >= 150 && dimension < 250) {
				this.compressQuality = 10;
			} else if (dimension >= 250 && dimension < 400) {
				this.compressQuality = 15;
			} else if (dimension >= 400 && dimension < 500) {
				this.compressQuality = 20;
			} else if (dimension >= 500 && dimension < 600) {
				this.compressQuality = 25;
			} else if (dimension >= 600 && dimension < 700) {
				this.compressQuality = 30;
			} else if (dimension >= 700 && dimension < 900) {
				this.compressQuality = 35;
			} else if (dimension >= 900 && dimension < 1500) {
				this.compressQuality = 40;
			} else {
				this.compressQuality = 45;
			}
		} else {
			if (this.indexSelected >= this.slideEnd) {
				this.slideEnd = this.indexSelected + 2;
			}
			this.slideOpts.initialSlide = this.indexSelected;
			this.isEdit = false;
			setTimeout(() => {
				this.showSlide = true;
			}, 100);
			setTimeout(() => {
				this.slides.ionSlideDidChange.subscribe(() => {
					this.slides.getActiveIndex().then((index) => {
						this.indexSelected = index;
					});
				});
			}, 500);
		}
	}

	async sendPhoto(idTemplate: number, photo: PhotoI = null) {
		if (this.photos.length === 0) {
			const macroTags = this.photo.MetaData.macroTags;
			this.photo.MetaData.IdAlbum = this.photo.AlbumModel.Id;
			if (this.album) {
				if (this.menusService.currentMenu.IsSharingAllowed) {
					this.photo.MetaData.Stato = PhotoStateEnum.archiveSede;
				} else {
					this.photo.MetaData.Stato = PhotoStateEnum.archiveAll;
				}
			} else {
				if (this.menusService.currentMenu.IsSharingAllowed) {
					this.photo.MetaData.Stato = PhotoStateEnum.gallerySede;
				} else {
					this.photo.MetaData.Stato = PhotoStateEnum.galleryAll;
				}
			}
			if (this.menusService.currentMenu.IsSharingAllowed) {
				// this.photo.IdTemplate = this.templateService.templateChoose$.value.IdSceltaTemplate;
				this.photo.IdTemplate = idTemplate;
			}
			this.photo.MetaData.isFromSalone = this.menusService.currentMenu.IsSharingAllowed ? '0' : '1';
			// @ts-ignore
			if (this.photo.MetaData.urlVideo) {
				// @ts-ignore
				this.photo.changeImage = { base64: this.photo.Link };
			}
			await this.templateService
				.savePhoto(
					this.photo.changeImage.base64,
					{
						...this.photo.MetaData,
						// @ts-ignore
						macroTags: JSON.stringify(macroTags),
					},
					true,
					this.compressQuality,
					this.isMultiType,
				)
				.toPromise()
				.then((resp) => {
					this.photo.Link = resp.Url;
					const urls: string[] = resp.Url.split('/');
					this.photo.Name = urls.slice(urls.length - 2, urls.length).join('/');
					this.toastService.presentToast(this.translate.instant('saveSuccess'));
					this.photo.MetaData.webp = this.photo.Link.replace('.jpeg', '.webp').replace('.png', '.webp');
					this.photo.MetaData.webp = this.photo.MetaData.webp.replace(
						'/' + this.photo.IdTemplate,
						'/' + this.photo.IdTemplate + '/webp',
					);
					this.photo.MetaData.thumbnail = this.photo.Link.replace(
						'/' + this.photo.IdTemplate,
						'/' + this.photo.IdTemplate + '/thumbnail',
					);
					this.close(this.photo);
				});
			return;
		}
		this.slides.getActiveIndex().then(async (index) => {
			if (photo != null) {
				this.photos[index] = photo;
			}
			this.photos[index].MetaData.IdAlbum = this.photos[index].AlbumModel ? this.photos[index].AlbumModel.Id : null;
			// const obs$ = [this.httpService.saveMetadata(this.photos[index])];
			const obs$ = [of(null)];
			if (this.photos[index].changeImage) {
				obs$.push(this.galleryService.patchImage(this.photos[index]));
			}

			forkJoin(obs$).subscribe(() => {
				if (obs$.length > 1) {
					this.photos[index].Link = this.photos[index].changeImage.base64;
					if (this.photos[index].MetaData.webp) {
						this.photos[index].MetaData.webp = this.photos[index].changeImage.base64;
					}
				}
				this.isEdit = false;
				this.toastService.presentToast(this.translate.instant('saveSuccess'));
				this.lockSlides();
			});
		});
	}

	setTags() {
		if (this.photos.length === 0) {
			this.modalCtrl
				.create({
					component: TagsComponent,
					componentProps: {
						selectedTags: this.photo.MetaData.macroTags,
						photo: this.photo,
						isGallery: false,
						isEdit: this.isEdit,
					},
					cssClass: 'full-modal',
				})
				.then((modal) => {
					modal.present();
					modal.onDidDismiss().then((result) => {
						if (result.data) {
							this.photo.MetaData.macroTags = result.data.macroTags;
							this.isEdit = result.data.isEdit;
						}
					});
				});
			return;
		}
		this.slides.getActiveIndex().then((index) => {
			const macroTags = this.photos[index].MetaData.macroTags || [];
			this.modalCtrl
				.create({
					component: TagsComponent,
					componentProps: {
						selectedTags: macroTags,
						photo: this.photos[index],
						isGallery: false,
						isEdit: this.isEdit,
					},
					cssClass: 'full-modal',
				})
				.then((modal) => {
					modal.present();
					modal.onDidDismiss().then((result) => {
						if (result.data) {
							this.photos[index].MetaData.macroTags = result.data.macroTags;
							this.isEdit = result.data.isEdit;
							this.lockSlides();
						}
					});
				});
		});
	}

	lockSlides() {
		this.slides.lockSwipes(this.isEdit);
	}

	close(data) {
		this.modalCtrl.dismiss(data);
	}

	deletePhoto(idTemplate: number) {
		this.slides.getActiveIndex().then(async (index) => {
			const item = this.photos[index];
			const name = item.MetaData && item.MetaData.name ? item.MetaData.name : '';
			this.alertCtrl
				.create({
					header: 'Alert',
					message: 'Are you sure you want to delete the photo ' + name + '?',
					buttons: [
						{
							text: this.translate.instant('dismiss'),
							role: 'cancel',
							handler: (data) => {
								event.preventDefault();
								event.stopImmediatePropagation();
							},
						},
						{
							text: 'Si',
							handler: (data) => {
								// const idTemplate = (this.usersService.currentUseInfo.isSede) ?
								//   this.templateService.templateChoose$.value.IdSceltaTemplate
								//   : this.templateService.templateChoose$.value.Id;
								this.galleryService.deleteImage(item, idTemplate).subscribe((resp) => {
									const i = this.photos.findIndex((e) => e.Name === item.Name);
									this.photos.splice(i, 1);
									this.photos = JSON.parse(JSON.stringify(this.photos));
									this.slides.update();
									this.isEdit = false;
									if (this.photos.length === 0) {
										this.modalCtrl.dismiss();
									}
								});
							},
						},
					],
				})
				.then((alert) => alert.present());
		});
	}

	openVisibility(event: any) {
		this.slides.getActiveIndex().then(async (index) => {
			const photo: PhotoI = this.photos[index];
			this.popoverController
				.create({
					component: VisibilityPhotoComponent,
					event,
					translucent: true,
					componentProps: { photo },
				})
				.then((menu) => {
					menu.present();
					menu.onDidDismiss().then((resp) => {
						if (resp.data) {
							this.sendPhoto(resp.data);
						}
					});
				});
		});
	}

	openUsers() {
		this.slides.getActiveIndex().then(async (index) => {
			const photo: PhotoI = this.photos[index];
			this.modalCtrl
				.create({
					component: PermissionsUsersComponent,
					componentProps: { photo },
					cssClass: ['full-modal'],
				})
				.then((modal) => {
					modal.present();
					modal.onDidDismiss().then((resp) => {});
				});
		});
	}

	showCompress() {
		this.modalCtrl
			.create({
				component: CompressComponent,
				componentProps: { compressQuality: this.compressQuality },
			})
			.then((modal) => {
				modal.present();
				modal.onDidDismiss().then((result) => {
					if (result && result.data != null) {
						this.compressQuality = result.data;
					}
				});
			});
	}

	isSharingAllowed() {
		return this.menusService.currentMenu.IsSharingAllowed;
	}

	assignToGroup() {
		this.modalCtrl
			.create({
				component: SelectGroupModalComponent,
				componentProps: {
					forAssigning: true,
					feature: GroupFeature.gallery,
				},
			})
			.then((modal) => {
				modal.present().then();
				modal.onDidDismiss().then((result) => {
					this.galleryService.editFileGroups(this.album.Id, result.data.Id, false).subscribe(() => {
						this.toastService.presentToast('Gruppi della foto aggiornati');
					});
				});
			});
	}
}
