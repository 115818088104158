import {Pipe, PipeTransform} from '@angular/core';
import {CategoriaCliente} from '../models/categoria-cliente.model';
import {PageListinoTemplate} from '../models/page-template.model';
import {Utility} from '../helpers/Utility';

@Pipe({
    name: 'matchDisabled'
})
export class MatchDisabledPipe implements PipeTransform {

    transform(values: CategoriaCliente[], currentIndex: number, listinos: PageListinoTemplate[], ...args: unknown[]): CategoriaCliente[] {
        return Utility.matchDisabled(values, currentIndex, listinos);
    }

}
