import { PhotoI } from '../components/gallery/PhotoI';
import { GalleryFileModel } from './gallery-file.model';

export class GalleryFileVModel {
	file: GalleryFileModel;
	isProcessing = false;
	newBase64: string = null;

	constructor(file: GalleryFileModel) {
		this.file = file;
	}

	get isVideo() {
		return !!this.file.UrlVideo;
	}

	get imageSrc() {
		if (this.file.IsFinished || this.file.IsDeleted) {
			return this.file.UrlWebp ?? this.file.Url ?? this.file.ThumbnailUrl;
		}
		return `data:image/${this.file.FileFormat};base64,${this.file.FileBase64}`;
	}

	get thumbnailSrc() {
		if (this.file.IsFinished || this.file.IsDeleted) {
			return this.file.ThumbnailUrl ?? this.file.UrlWebp ?? this.file.Url;
		}
		return `data:image/${this.file.FileFormat};base64,${this.file.FileBase64}`;
	}

	toPhotoModel(): PhotoI {
		const photo: PhotoI = {
			GalleryFile: this,
			Link: this.imageSrc,
			IdTemplate: null,
			GalleryFileId: this.file.Id,
			MetaData: {
				name: this.file.Name,
				desc: this.file.Description,
				favorite: this.file.IsFavorite ? '1' : '0',
				macroTags: this.file.TagCategories,
				thumbnail: this.file.ThumbnailUrl,
				webp: this.file.UrlWebp,
				IdAlbum: this.file.AlbumId,
				Stato: this.file.State,
				urlVideo: this.file.UrlVideo,
				nameGallery: this.file.NameGallery,
				isFromSede: this.file.IsFromSede ? '1' : '0',
				isFromSalone: this.file.IsFromSalone ? '1' : '0',
			},
			AlbumModel: null,
			Name: this.file.Name,
			NomeTemplate: null,
		};

		return photo;
	}
}
