import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IMenu } from '../models/menu';
import { CatalogPageGeneral } from '../pages/menu/menu-editor/catalog/models/catalog-page-general';
import { CatalogPageService } from '../pages/menu/menu-editor/catalog/models/catalog-page-service';
import { CatalogPageServiceList } from '../pages/menu/menu-editor/catalog/models/catalog-page-service-list';
import { MenusService } from './menus.service';
import { GalleryFileVModel } from '../models/gallery-file-v.model';
import { NavigationEnd, Router } from '@angular/router';
import { SeminarCategory } from '../interfaces/seminars/seminar-category.interface';

@Injectable({
	providedIn: 'root',
})
export class SelectedTemplatesService {
	allTemplates$ = new BehaviorSubject<IMenu[]>([]);

	availableGalleryTemplates$ = new BehaviorSubject<IMenu[]>([]);
	availableArchiveTemplates$ = new BehaviorSubject<IMenu[]>([]);
	availableEventTemplates$ = new BehaviorSubject<IMenu[]>([]);

	selectedGalleryTemplates$ = new BehaviorSubject<IMenu[] | undefined>(undefined);
	selectedArchiveTemplates$ = new BehaviorSubject<IMenu[] | undefined>(undefined);
	selectedEventTemplates$ = new BehaviorSubject<IMenu[] | undefined>(undefined);

	private _previousPage: string | undefined = undefined;

	constructor(
		private menusService: MenusService,
		private router: Router,
	) {
		this._loadAllTemplates();
		this._filterOnNavigationChange();
	}

	public filterGalleryTemplates(files: GalleryFileVModel[]) {
		this.availableGalleryTemplates$.next(
			this.allTemplates$.value.filter((template) => files.some((file) => file.file.TemplateId === template.Id)),
		);

		if (!this.selectedGalleryTemplates$.value)
			this.selectedGalleryTemplates$.next([...this.availableGalleryTemplates$.value]);
	}

	public filterArchiveTemplates(files: GalleryFileVModel[]) {
		this.availableArchiveTemplates$.next(
			this.allTemplates$.value.filter((template) => files.some((file) => file.file.TemplateId === template.Id)),
		);

		if (!this.selectedArchiveTemplates$.value) {
			this.selectedArchiveTemplates$.next([...this.availableArchiveTemplates$.value]);
		}
	}

	public filterEventTemplates(categories: SeminarCategory[]) {
		console.log('filtering event templates', categories);

		this.availableEventTemplates$.next(
			this.allTemplates$.value.filter((template) => categories.some((event) => event.PageTemplateID === template.Id)),
		);

		if (!this.selectedEventTemplates$.value)
			this.selectedEventTemplates$.next([...this.availableEventTemplates$.value]);
	}

	private _filterOnNavigationChange() {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				const currentPage = this.router.url;

				// Don't filter if it's the first page load
				if (!this._previousPage) {
					this._previousPage = currentPage;
					return;
				}

				// Get selected templates from the previous tab
				const previousTemplates = this._getSelectedTemplatesFromPreviousTab();

				// Depending on the current tab, filter the available templates
				this._filterTemplatesBasedOnPage(currentPage, previousTemplates);

				// Instead of updating _previousPage immediately, defer it until after filtering is done
				this._waitForTemplatesToLoad(currentPage).then(() => {
					// Only update _previousPage after templates are loaded for the new page
					this._previousPage = currentPage;
				});
			}
		});

		// Subscribe to changes in available template lists and trigger filtering for the current tab
		this._subscribeToTemplateLists();
	}

	private _waitForTemplatesToLoad(currentPage: string): Promise<void> {
		return new Promise((resolve) => {
			if (currentPage.includes('archive')) {
				const sub = this.availableArchiveTemplates$.subscribe((templates) => {
					if (templates.length > 0) {
						sub?.unsubscribe();
						resolve();
					}
				});
			} else if (currentPage.includes('gallery')) {
				const sub = this.availableGalleryTemplates$.subscribe((templates) => {
					if (templates.length > 0) {
						sub?.unsubscribe();
						resolve();
					}
				});
			} else if (currentPage.includes('seminars')) {
				const sub = this.availableEventTemplates$.subscribe((templates) => {
					if (templates.length > 0) {
						sub?.unsubscribe();
						resolve();
					}
				});
			} else {
				// If it's a page without templates, resolve immediately
				resolve();
			}
		});
	}

	// Helper method to subscribe to the available template lists
	private _subscribeToTemplateLists() {
		// Subscribe to available archive templates and trigger filtering if it changes
		this.availableArchiveTemplates$.subscribe(() => {
			const currentPage = this.router.url;
			if (currentPage.includes('archive')) {
				const previousTemplates = this._getSelectedTemplatesFromPreviousTab();
				this._filterTemplatesForCurrentTab(
					previousTemplates,
					this.availableArchiveTemplates$,
					this.selectedArchiveTemplates$,
				);
			}
		});

		// Subscribe to available gallery templates and trigger filtering if it changes
		this.availableGalleryTemplates$.subscribe(() => {
			const currentPage = this.router.url;
			if (currentPage.includes('gallery')) {
				const previousTemplates = this._getSelectedTemplatesFromPreviousTab();
				this._filterTemplatesForCurrentTab(
					previousTemplates,
					this.availableGalleryTemplates$,
					this.selectedGalleryTemplates$,
				);
			}
		});

		// Subscribe to available event templates and trigger filtering if it changes
		this.availableEventTemplates$.subscribe(() => {
			const currentPage = this.router.url;
			if (currentPage.includes('seminars')) {
				const previousTemplates = this._getSelectedTemplatesFromPreviousTab();
				this._filterTemplatesForCurrentTab(
					previousTemplates,
					this.availableEventTemplates$,
					this.selectedEventTemplates$,
				);
			}
		});
	}

	// Helper method to handle filtering logic based on the current page
	private _filterTemplatesBasedOnPage(currentPage: string, previousTemplates: IMenu[] | undefined) {
		if (currentPage.includes('archive')) {
			this._filterTemplatesForCurrentTab(
				previousTemplates,
				this.availableArchiveTemplates$,
				this.selectedArchiveTemplates$,
			);
		} else if (currentPage.includes('gallery')) {
			this._filterTemplatesForCurrentTab(
				previousTemplates,
				this.availableGalleryTemplates$,
				this.selectedGalleryTemplates$,
			);
		} else if (currentPage.includes('seminars')) {
			this._filterTemplatesForCurrentTab(
				previousTemplates,
				this.availableEventTemplates$,
				this.selectedEventTemplates$,
			);
		}
	}

	private _filterTemplatesForCurrentTab(
		previousTemplates: IMenu[] | undefined,
		availableTemplates$: BehaviorSubject<IMenu[]>,
		selectedTemplates$: BehaviorSubject<IMenu[] | undefined>,
	) {
		if (!previousTemplates) return;

		const prevAvailableTemplates = this._getAvailableTemplatesFromPreviousTab();

		// If all templates were selected in the previous tab, select all available templates
		if (previousTemplates.length === prevAvailableTemplates?.length) {
			selectedTemplates$.next([...availableTemplates$.value]);
			return;
		}

		if (previousTemplates.length === this.allTemplates$.value.length) {
			// If all templates were selected, don't filter the available templates
			selectedTemplates$.next([...availableTemplates$.value]);
		} else {
			// Filter templates that exist in the available templates
			const matchingTemplates = availableTemplates$.value.filter((template) =>
				previousTemplates.some((prevTemplate) => prevTemplate.Id === template.Id),
			);

			// Select matching templates or deselect if none match
			if (matchingTemplates.length > 0) {
				selectedTemplates$.next(matchingTemplates);
			} else {
				selectedTemplates$.next(undefined);
			}
		}
	}

	private _getAvailableTemplatesFromPreviousTab(): IMenu[] | undefined {
		if (!this._previousPage) {
			return undefined;
		}

		if (this._previousPage.includes('gallery')) {
			return this.availableGalleryTemplates$.value;
		} else if (this._previousPage.includes('archive')) {
			return this.availableArchiveTemplates$.value;
		} else if (this._previousPage.includes('seminars')) {
			return this.availableEventTemplates$.value;
		}

		return undefined;
	}

	private _getSelectedTemplatesFromPreviousTab(): IMenu[] | undefined {
		if (!this._previousPage) {
			// Return all the available templates for the current tab
			const currentPage = this.router.url;

			if (currentPage.includes('gallery')) {
				return this.selectedGalleryTemplates$.value;
			} else if (currentPage.includes('archive')) {
				return this.selectedArchiveTemplates$.value;
			} else if (currentPage.includes('seminars')) {
				return this.selectedEventTemplates$.value;
			}
		}

		if (this._previousPage.includes('gallery')) {
			return this.selectedGalleryTemplates$.value;
		} else if (this._previousPage.includes('archive')) {
			return this.selectedArchiveTemplates$.value;
		} else if (this._previousPage.includes('seminars')) {
			return this.selectedEventTemplates$.value;
		}

		return undefined;
	}

	private _loadAllTemplates() {
		this.menusService.getAllMenus(false).subscribe((res) => {
			res.forEach((menuItem) => {
				const allPages = [...menuItem.GeneralPages, ...menuItem.ServicePages, ...menuItem.ServiceListPages].filter(
					(page) => page !== null && page.IsPublished,
				);

				allPages.sort((a, b) => a.Order - b.Order);

				const previewPage = allPages[0];

				if (previewPage) {
					if (previewPage.IsGeneralPage) {
						menuItem.previewType = 'general';
						menuItem.generalPagePreview = CatalogPageGeneral.createFromDto(previewPage as any);
					} else if (previewPage.IsServicePage) {
						menuItem.previewType = 'service';
						menuItem.servicePagePreview = CatalogPageService.createFromDto(previewPage as any);
					} else if (previewPage.IsServiceListPage) {
						menuItem.previewType = 'serviceList';
						menuItem.serviceListPagePreview = CatalogPageServiceList.createFromDto(previewPage as any);
					}
				}
			});

			this.allTemplates$.next(res);
		});
	}
}
