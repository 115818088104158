import { Platform } from '@ionic/angular';
import { ElementRef } from '@angular/core';
import html2canvas from 'html2canvas';
import { environment } from '../environments/environment';

export class AppHelper {
	public static redirectUrl(url: string, isOpen: boolean = false) {
		if (!url) return;

		if (isOpen) {
			window.open(url);
		} else {
			window.location.href = url;
		}
	}

	public static redirectToAppleStore() {
		this.redirectUrl(
			`https://apps.apple.com/it/app/${environment.appName.replace(' ', '-').toLowerCase()}/id${environment.appleId}`,
		);
	}

	public static redirectToGoogleStore() {
		this.redirectUrl(
			`https://play.google.com/store/apps/details?id=${environment.bundleName}${environment.bundleDomain}&pli=1`,
		);
	}

	public static storeRedirectionHandler(platform: Platform) {
		if (!environment.hasMobileApp) {
			AppHelper.reloadPage();
			return;
		}

		if (platform.is('android')) {
			AppHelper.redirectToGoogleStore();
		} else if (platform.is('ios') && (platform.is('iphone') || platform.is('ipad'))) {
			AppHelper.redirectToAppleStore();
		} else {
			AppHelper.reloadPage();
		}
	}

	public static reloadPage() {
		window.location.reload();
	}

	/// Returns the component as an image in base64 format
	static async getComponentAsImage(elementRef: ElementRef) {
		const element = elementRef.nativeElement;
		let base64: string;

		await html2canvas(element, {
			useCORS: true,
		}).then((canvas) => {
			base64 = canvas.toDataURL('image/png');
		});

		return base64;
	}
}
