import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LoadingService {
	loading: HTMLIonLoadingElement;

	constructor(
		private loadingCtrl: LoadingController,
		private translateService: TranslateService,
	) {}

	show() {
		if (!this.loading) {
			this.loadingCtrl
				.create({
					message: this.translateService.instant('loading'),
					translucent: true,
				})
				.then((ctrlRef) => {
					this.loading = ctrlRef;
					this.loading.present().then();
				});
		}
	}

	hide() {
		if (this.loading) {
			this.loading.dismiss().then(() => {
				this.loading = null;
			});
		} else {
			setTimeout(() => {
				this.hide();
			}, 100);
		}
	}
}
